.compass_widget {
    height: var(--size);
    width: var(--size);
    perspective: calc(var(--size) * 10);
    display: flex;
    pointer-events: none;
}

.compass {
    --compass-size: calc(0.4 * var(--size));
    --circle-size: calc(0.5 * var(--size));
    --line-size: calc(2 * var(--compass-size) - var(--label-size));
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transform: rotateX(var(--theta)) rotateY(var(--phi)) rotateX(90deg);
}

.compass .compass_circle {
    position: absolute;
    box-sizing: border-box;
    width: var(--circle-size);
    height: var(--circle-size);
    /* Place at center of parent */
    top: calc(50% - 0.5 * var(--circle-size));
    left: calc(50% - 0.5 * var(--circle-size));
    border-radius: 50%;
    border: 1px solid black;
    background: #30383b60;
}

/* This places the labels on each direction */
.compass .compass_direction {
    --x: 0;
    --y: 0;
    --z: 0;

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--label-size);
    height: var(--label-size);
    /* Place at center of parent */
    top: calc(50% - 0.5 * var(--label-size));
    left: calc(50% - 0.5 * var(--label-size));
    /* color: set by coordinate-component-{x,y,z} class */
    background: black;
    box-sizing: border-box;
    border-radius: 50%;
    font: bold 14px 'futura-pt';
    pointer-events: auto;
    user-select: none;
    cursor: pointer;
    transform: translate3d(
            calc(var(--x) * var(--compass-size)),
            calc(var(--y) * var(--compass-size)),
            calc(var(--z) * var(--compass-size))
        )
        rotateX(-90deg) rotateY(calc(-1 * var(--phi))) rotateX(calc(-1 * var(--theta)));
}

.compass .compass_direction.south {
    --y: 1;
}

.compass .compass_direction.north {
    --y: -1;
}

.compass .compass_direction.south:hover,
.compass .compass_direction.north:hover,
.compass .compass_direction.south.active,
.compass .compass_direction.north.active {
    border-color: var(--Y);
}

.compass .compass_direction.east {
    --x: 1;
}

.compass .compass_direction.west {
    --x: -1;
}

.compass .compass_direction.east:hover,
.compass .compass_direction.west:hover,
.compass .compass_direction.east.active,
.compass .compass_direction.west.active {
    border-color: var(--X);
}

.compass .compass_direction.up {
    --z: 1;
}

.compass .compass_direction.down {
    --z: -1;
}

.compass .compass_direction.up:hover,
.compass .compass_direction.down:hover,
.compass .compass_direction.up.active,
.compass .compass_direction.down.active {
    border-color: var(--Z);
}

/* This sets the lines between the labels.
   We need two of them rotated 90° for the line to be correctly displayed
   whatever the orientation.
*/
.compass .compass_line,
.compass .compass_line-cross {
    position: absolute;
    border-top: 2px solid;
    width: var(--line-size);
    top: 50%;
    left: calc(50% - 0.5 * var(--line-size));
}

.compass .compass_line.x {
    border-color: #f00;
    /* no transform, we're aleady OK */
}

.compass .compass_line-cross.x {
    border-color: #f00;
    transform: rotateX(90deg);
}

.compass .compass_line.y {
    border-color: #0f0;
    transform: rotateZ(90deg);
}

.compass .compass_line-cross.y {
    border-color: #0f0;
    transform: rotateZ(90deg) rotateX(90deg);
}

.compass .compass_line.z {
    border-color: #00f;
    transform: rotateY(90deg);
}

.compass .compass_line-cross.z {
    border-color: #00f;
    transform: rotateY(90deg) rotateX(90deg);
}
