html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
}

body {
    --bs-font-sans-serif: 'futura-pt', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--bs-font-sans-serif);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --bs-body-bg: var(--dark-gray-1);
    --bs-body-color: var(--text-medium-emphasis);
    --bs-border-color: #505050;
    --spacer: 0.5rem;
    --border-radius: 0.5rem;
    --menu-bg-color: var(--dark-gray-0);
    --menu-highlight-color: var(--dark-gray-1);
    --menu-active-color: #67ce8e;
    --menu-close-color: #fcb72b;
    --element-border-color: #505050;
    --menu-delete-color: #fc161b;
    --menu-border-width: 2px;
    --menu-border-color: var(--dark-gray-0);
    --menu-overlay-border-color: #707070;
    --tabs-border-color: var(--menu-highlight-color);
    --bs-nav-link-font-size: 1.5rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}
